import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  constructor(public deviceService: DeviceDetectorService,
              private router: Router ) {}

  ngOnInit(): void {
    // const device = this.deviceService.getDeviceInfo();
    // if (device.os.indexOf('iOS') !== -1) {
    //   window.location.href = 'https://apps.apple.com/ru/app/id1499805544';
    // }
    // if (device.os.indexOf('Android') !== -1) {
    //   window.location.href = 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.octopod.service112';
    // }
  }

  //
  // goTo(url: string) {
  //   window.open(url, '_blank');
  // }
  //
  // openPdf(url: string) {
  //   window.open(url);
  // }

}

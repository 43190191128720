import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CarouselComponent } from './shared/components/carousel/carousel.component';
import { ClipboardModule } from 'ngx-clipboard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AgreementComponent } from './shared/components/agreement/agreement.component';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './shared/components/main/main.component';
import { AgreeNovosibComponent } from './shared/components/agree-novosib/agree-novosib.component';
import { AgreeKurskComponent } from './shared/components/agree-kursk/agree-kursk.component';
import { AgreeAnonComponent } from './shared/components/agree-anon/agree-anon.component';
import { SiteHeaderComponent } from './shared/components/site-header/site-header.component';
import { SiteFooterComponent } from './shared/components/site-footer/site-footer.component';
import { StartPageComponent } from './shared/components/start-page/start-page.component';
import { System112Component } from './shared/components/system112/system112.component';
import { SocialMonitoringComponent } from './shared/components/social-monitoring/social-monitoring.component';
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

const appRoutes: Routes =[
  { path: '', component: MainComponent,
    children: [
      {
        path: '', component: StartPageComponent
      },
      {
        path: 'system112', component: System112Component
      },
      {
        path: 'social-monitoring', component: SocialMonitoringComponent
      }
    ]
  },
  { path: 'agreement', component: AgreementComponent},
  { path: 'agreeNovosib', component: AgreeNovosibComponent},
  { path: 'agreeKursk', component: AgreeKurskComponent},
  { path: 'agreeRegion', component: AgreeAnonComponent},

];


@NgModule({
  declarations: [
    AppComponent,
    CarouselComponent,
    AgreementComponent,
    MainComponent,
    AgreeNovosibComponent,
    AgreeKurskComponent,
    AgreeAnonComponent,
    SiteHeaderComponent,
    SiteFooterComponent,
    StartPageComponent,
    System112Component,
    SocialMonitoringComponent,
  ],
    imports: [
        BrowserModule,
        SlickCarouselModule,
        ClipboardModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        RouterModule.forRoot(appRoutes),
        FormsModule,
        HttpClientModule
    ],
  exports: [RouterModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="cover">
    <div class="cover__wrapper">
        <div class="cover__info">
            <div class="cover__info-content">
                <div class="cover__info-title">Система 112 — <br /> в мобильном приложении</div>
                <div class="cover__info-dscr">Мобильные приложения, которые помогают населению
                    быть на связи с экстренными службами, отправлять заявки и соблюдать режим самоизоляции.
                    Помощь гражданам — ещё оперативнее.</div>
                <a href="#contacts" class="button">Связаться с нами</a>
            </div>
            <div class="cover__bg">
                <img src="assets/images/main.png" alt="" class="cover__bg-img">
            </div>
        </div>
        <div class="cover__note">
            <div class="cover__note-text">Разработчик –</div>
            <a href="https://octopod.com/">
                <div class="cover__note-img">
                    <img src="assets/images/octo-logo-dark.png" alt=""
                         class="cover__note-img-pic"></div>
            </a>
        </div>
    </div>
</div>
<div class="info">
    <div class="info__wrapper wrapper">
        <div class="info__items">
            <div class="info__item">
                <div class="info__item-bg">
                    <img src="assets/images/info-pic_1-bg.png" alt="" class="info__item-bg-img">
                </div>
                <div class="info__item-content">
                    <div class="info__item-info">
                        <div class="info__item-header">
                            <div class="info__item-title">112 – Экстренная помощь</div>
                            <div class="info__item-title-sub">Мобильное приложение</div>
                        </div>
                        <div class="info__item-info-blocks">
                            <div class="info__item-info-sep info__item-info-sep--dscr">
                                <div class="info__item-dscr">С помощью приложения пользователи могут связаться со Службой-112 и подробно описать ситуацию.
                                    Приложение интегрировано с Системой-112 — заявка мгновенно отобразится на экране оператора. Оператору будут доступны описние просишествия, gps-координаты, контакты и фотографии просишествия — это облегчит работу спасателям в экстренной ситуации.
                                    Пользователь может отслеживать в приложении статус заявки и видеть на карте местоположение автомобилей служб экстренного реагирования.</div>
                                <div class="info__icons">
                                    <div class="info__icons-item">
                                        <div class="info__icons-item-img">
                                            <img src="assets/images/112-location.png" alt="" class="info__icons-item-img-pic">
                                        </div>
                                        <div class="info__icons-item-text">Точная геолокация</div>
                                    </div>
                                    <div class="info__icons-item">
                                        <div class="info__icons-item-img">
                                            <img src="assets/images/112-media.png" alt="" class="info__icons-item-img-pic">
                                        </div>
                                        <div class="info__icons-item-text">Фото, видео, аудиозаписи</div>
                                    </div>
                                    <div class="info__icons-item">
                                        <div class="info__icons-item-img">
                                            <img src="assets/images/112-videocall.png" alt="" class="info__icons-item-img-pic">
                                        </div>
                                        <div class="info__icons-item-text">Для людей <br /> с ограниченными возможностями</div>
                                    </div>
                                </div>
                                <a (click)="scrollTop()" [routerLink]="['/system112']" class="button">Подробнее</a>
                            </div>
                            <div class="info__item-info-sep">
                                <div class="info__item-img">
                                    <img  src="assets/images/info-pic_1.png" class="info__item-img-picture"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="info__item info__item--blue">
                <div class="info__item-content">
                    <div class="info__item-bg">
                        <img src="assets/images/info-pic_2-bg.png" alt="" class="info__item-bg-img">
                    </div>
                    <div class="info__item-info">
                        <div class="info__item-header">
                            <div class="info__item-title">Социальный мониторинг</div>
                            <div class="info__item-title-sub">Мобильное приложение</div>
                        </div>
                        <div class="info__item-info-blocks">
                            <div class="info__item-info-sep info__item-info-sep--dscr">
                                <div class="info__item-dscr">Решение по контролю за соблюдением самоизоляции позволяет отслеживать местоположение граждан и запрашивать обратную связь в случае предполагаемых нарушений.
                                    С помощью мобильного приложения операторы экстренных служб могут контролировать самочувствие граждан и при необходимости подключать экстренные службы.</div>
                                <div class="info__icons">
                                    <div class="info__icons-item">
                                        <div class="info__icons-item-img">
                                            <img src="assets/images/social-location.png" alt="" class="info__icons-item-img-pic">
                                        </div>
                                        <div class="info__icons-item-text">Контроль соблюдения самоизоляции</div>
                                    </div>
                                    <div class="info__icons-item">
                                        <div class="info__icons-item-img">
                                            <img src="assets/images/social-monitoring.png" alt="" class="info__icons-item-img-pic">
                                        </div>
                                        <div class="info__icons-item-text">Мониторинг самочувствия </div>
                                    </div>
                                    <div class="info__icons-item">
                                        <div class="info__icons-item-img">
                                            <img src="assets/images/social-doctor.png" alt="" class="info__icons-item-img-pic">
                                        </div>
                                        <div class="info__icons-item-text">Вызов врача и <br /> скорой помощи</div>
                                    </div>
                                </div>
                                <a (click)="scrollTop()" [routerLink]="['/social-monitoring']" class="button button--blue">Подробнее</a>
                            </div>
                            <div class="info__item-info-sep">
                                <div class="info__item-img">
                                    <img  src="assets/images/info-pic_2.png" class="info__item-img-picture"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="about" id="developer">
    <div class="about__wrapper wrapper">
        <div class="about__header">
            <div class="about__header-title">Разработчик приложений – </div>
            <div class="about__header-img">
                <a href="https://octopod.com/">
                    <img class="about__header-img-pic" src="assets/images/developer-octo.png" alt="">
                </a>
            </div>
        </div>
        <div class="about__info">
            Оctopod – технологическая компания, основанная в 2008 году. Наша компания разрабатывает мобильные приложения, web и серверные решения, выполняя полный комплекс работ по проектированию, разработке и внедрению решений. 14 лет мы успешно разрабатываем информационные системы для финансовой, строительной, государственной и других областей. Успешно сотрудничаем как с молодыми компаниями так и с крупнейшими компаниями на рынке.
        </div>
        <div class="about__cards">
            <div class="about__cards-item">
                <div class="about__cards-item-info">
                    <b>14</b> лет на рынке разработки мобильных приложений, web и комплексных информационных систем
                </div>
            </div>
            <div class="about__cards-item">
                <div class="about__cards-item-info">
                    <b>100+</b> реализованных проектов в финансовой, строительной, государственной и других областях
                </div>
            </div>
            <div class="about__cards-item">
                <div class="about__cards-item-info">
                    Команда из <b>80</b> квалифицированных разрабочтков, дизайнеров, тестировщиков и аналитиков
                </div>
            </div>
        </div>
        <a class="button" href="https://octopod.com/">Сайт компании</a>
    </div>
</div>
<div class="contact" id="contacts">
    <div class="contact__wrapper wrapper">
        <div class="contact__items">
            <div class="contact__item" *ngIf="!this.isSubmitted">
                <div class="contact__header">
                    <div class="contact__header-title">Напишите нам</div>
                    <div class="contact__header-sub">Напишите нам и мы ответим в ближайшее время на все интересующие вас вопросы!</div>
                </div>
                <div class="contact__form">
                    <div class="contact__form-content">
                        <div class="contact__form-item">
                            <input [(ngModel)]="fromName" class="input" type="text" placeholder="Ваше имя">
                        </div>
                        <div class="contact__form-item">
                            <input
                              [ngClass]="{'error' : email.touched && email.invalid
                              && (email.control.hasError('pattern')
                              ||  email.control.hasError('required'))}"
                              name="email"
                              #email="ngModel"
                              required="required"
                              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
                              [(ngModel)]="fromEmail"
                              class="input"
                              type="email" placeholder="Email">
                        </div>
                        <div class="contact__form-item">
                            <textarea [(ngModel)]="text" class="textarea" placeholder="Комментарий"></textarea>
                        </div>
                    </div>
                    <button (click)="sendEmail()"  class="button">Отправить</button>
                </div>
            </div>
            <ng-container *ngIf="this.isSubmitted">
                <div class="contact__item contact__item--border">
                    <div class="contact__header">
                        <div class="contact__header-title">Напишите нам</div>
                        <div class="contact__header-sub">Напишите нам и мы ответим в ближайшее время на все интересующие вас вопросы!</div>
                    </div>
                    <div class="contact__sent">
                        <div class="contact__sent-header">
                            <div class="contact__sent-header-title">Сообщение отправлено!</div>
                        </div>
                        <div class="contact__sent-info">
                            Наши сотрудники скоро свяжутся с вами и ответят на все интересующие вопросы.
                        </div>

                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

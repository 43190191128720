import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  slides = [
    { img: '/assets/images/screen-001.png', text: 'Информирование и новости МЧС' },
    { img: '/assets/images/screen-002.png', text: 'Определение местоположения' },
    { img: '/assets/images/screen-003.png', text: 'Вызов спасателей Службы-112' },
    { img: '/assets/images/screen-004.png', text: 'Больше информации в заявке' },
    { img: '/assets/images/screen-005.png', text: 'Отслеживание статуса заявки' }
  ];

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    variableWidth: true,
    centerMode: true,
    initialSlide: 2,
    focusOnSelect: true
  };

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="cover">
    <div class="cover__wrapper wrapper">
        <div class="cover__items">
            <div class="cover__item">
                <div class="cover__info">
                    <div class="cover__info-title">Мобильное приложение</div>
                    <div class="cover__info-sub">112 – Экстренная помощь</div>
                </div>
                <div class="cover__dscr">
                    Приложение дает новые возможности для связи с экстренными службами:
                </div>
                <ul class="cover__lists">
                    <li class="cover__list">
                        Отправка заявки о происшествии для вызова экстренных служб онлайн.
                    </li>
                    <li class="cover__list">
                        Передача дополнительных данных, упрощающих спасателям оказание помощи – фото, видеоматериалов, контактов, точного местоположения пользователя.
                    </li>
                    <li class="cover__list">
                        Отслеживание статуса отправленной заявки.
                    </li>
                    <li class="cover__list">
                        Связь для людей с ограниченными возможностями.
                    </li>
                    <li class="cover__list">
                        Получение информационных уведомлений и новостей МЧС.
                    </li>
                </ul>
                <div class="cover__item-img">
                    <img class="cover__item-img-pic"  src="assets/images/system112-cover.png">
                </div>
                <div class="cover__apps">
                    <div class="cover__apps-item">
                        <button class="button button--app appstore"
                                type="button"
                                (click)="goTo('https://apps.apple.com/ru/app/id1499805544')"
                        ></button>
                    </div>
                    <div class="cover__apps-item">
                        <button class="button button--app google"
                                type="button"
                                (click)="goTo('https://play.google.com/store/apps/details?id=com.octopod.service112')"
                        ></button>
                    </div>
                </div>
                <div class="cover__descriptor">
                    <div class="cover__descriptor-item">
                        <a href="mailto:pm@octopod.com" class="button">Связаться с нами</a>
                    </div>
                    <div class="cover__descriptor-item">
                        <div class="cover__descriptor-note">Напишите нам, чтобы узнать условия и стоимость внедрения решения в вашем регионе.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="cards">
    <div class="cards__item">
        <div class="cards__item-wrapper wrapper">
            <div class="cards__item-content">
                <div class="cards__item-header">
                    <div class="cards__item-title">Отправка заявки в службу 112</div>
                </div>
                <div class="cards__item-info">
                    <div class="cards__item-dscr">
                        Нажимая кнопку SOS, пользователь открывает форму заявки.
                        Здесь он может передать подробные данные о происшествии:
                    </div>
                    <ul class="cards__item-lists">
                        <li class="cards__item-list">Адрес происшествия и координаты своего метосположения</li>
                        <li class="cards__item-list">Информацию о пострадавших</li>
                        <li class="cards__item-list">Тип происшествия</li>
                        <li class="cards__item-list">Описание</li>
                        <li class="cards__item-list">Фото, видео и аудиозаписи</li>
                    </ul>
                    <div class="cards__item-dscr">
                        Важно, что любые данные не обязательны для заполнения.
                        Пользователь может сразу подтвердить вызов —
                        заявка незамедлительно отправится в Систему-112.
                    </div>
                </div>
                <div class="cards__item-img">
                    <img src="assets/images/cards_1.png" alt="" class="cards__item-img-pic">
                </div>
            </div>
        </div>
    </div>
    <div class="cards__item">
        <div class="cards__item-wrapper wrapper">
            <div class="cards__item-content">
                <div class="cards__item-header">
                    <div class="cards__item-title">Геолокация и определение адреса</div>
                </div>
                <div class="cards__item-dscr">
                    <div class="cards__item-dscr-info">
                        Приложение использует геоданные GPS и ГЛОНАСС модулей смартфонов. Точность определения местоположения пользователя — до 10 метров. Это важно для оперативной работы экстренных служб.
                    </div>
                    <div class="cards__item-dscr-info">
                        Когда пользователь оформляет заявку, его координаты добавляются автоматически.
                    </div>
                    <div class="cards__item-dscr-info">
                        Адрес происшествия определеяется автоматически по координатам пользователя, но может быть скорректирован — введен вручную или отмечен точкой на карте.
                    </div>
                </div>
                <div class="cards__item-img">
                    <img src="assets/images/cards_2.png" alt="" class="cards__item-img-pic">
                </div>
            </div>
        </div>
    </div>
    <div class="cards__item">
        <div class="cards__item-wrapper wrapper">
            <div class="cards__item-content">
                <div class="cards__item-header">
                    <div class="cards__item-title">Видеозвонок для людей с ограниченными возможностями</div>
                </div>
                <div class="cards__item-info">
                    <div class="cards__item-dscr">
                        <div class="cards__item-dscr-info">
                            Мы позаботились о людях с ограниченными возможностями —
                            в приложении доступна функция видеозвонка.
                        </div>
                        <div class="cards__item-dscr-info">
                            Видеозвонок совершается непосредственно из приложения с использованием протокола SIP. Видеозвонок автоматически выводится на монитор оператора. При необходимости к звонку подключается оператор с навыками сурдоперевода.
                        </div>
                    </div>
                </div>
                <div class="cards__item-img">
                    <img src="assets/images/cards_3.png"
                         alt=""
                         class="cards__item-img-pic">
                </div>
            </div>
        </div>
    </div>
    <div class="cards__item">
        <div class="cards__item-wrapper wrapper">
            <div class="cards__item-content">
                <div class="cards__item-header">
                    <div class="cards__item-title">Помощь в реальном времени</div>
                </div>
                <div class="cards__item-info">
                    <div class="cards__item-dscr">
                        <div class="cards__item-dscr-info">
                            Пользователю всегда доступен актуальный статус его заявки. В режиме реального времени можно посмотреть,
                            какие службы экстренного реагирования подключены к выполнению заявки и когда прибудут машины спасателей (при наличии технической возможности).
                        </div>
                        <div class="cards__item-dscr-info">
                            История заявок хранится в приложении.
                        </div>
                    </div>
                </div>
                <div class="cards__item-img">
                    <img src="assets/images/cards_4.png"
                         alt=""
                         class="cards__item-img-pic">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="docs">
    <div class="docs__wrapper wrapper">
        <div class="docs__header">
            <div class="docs__title">Документация</div>
        </div>
        <div class="docs__items">
            <div class="docs__item">
                <div class="docs__item-header">
                    <div class="docs__item-title">Руководства пользователей</div>
                </div>
                <div class="docs__item-elem" (click)="openPdf('assets/pdf/112_instruction.pdf')">
                    <div class="docs__item-icon">
                        <img src="assets/images/instruction.svg"
                             alt=""
                             class="docs__item-icon-pic">
                    </div>
                    <div class="docs__item-info">Руководство к мобильному приложению «112 – Экстренная помощь»</div>
                </div>
                <div class="docs__item-elem" (click)="openPdf('assets/pdf/112covid_instruction.pdf')">
                    <div class="docs__item-icon">
                        <img src="assets/images/instruction.svg"
                             alt=""
                             class="docs__item-icon-pic">
                    </div>
                    <div class="docs__item-info">
                        Руководство к разделу Карантин-социальный мониторинг (COVID-19)
                    </div>
                </div>
            </div>
            <div class="docs__item">
                <div class="docs__item-header">
                    <div class="docs__item-title">Безопасность и конфиденциальность</div>
                </div>
                <div class="docs__item-elem" [routerLink]="['/', 'agreement']">
                    <div class="docs__item-icon">
                        <img src="assets/images/instruction.svg"
                             alt=""
                             class="docs__item-icon-pic">
                    </div>
                    <div class="docs__item-info">Пользовательское соглашение приложения «112 – Экстренная помощь»</div>
                </div>
                <div class="docs__item-elem" [routerLink]="['/', 'agreeKursk']">
                    <div class="docs__item-icon">
                        <img src="assets/images/instruction.svg"
                             alt=""
                             class="docs__item-icon-pic">
                    </div>
                    <div class="docs__item-info">Условия обработки Персональных Данных (Курская область)</div>
                </div>
                <div class="docs__item-elem" [routerLink]="['/', 'agreeNovosib']">
                    <div class="docs__item-icon">
                        <img src="assets/images/instruction.svg"
                             alt=""
                             class="docs__item-icon-pic">
                    </div>
                    <div class="docs__item-info">Условия обработки Персональных Данных (Новосибирская область)</div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="contact" id="contacts">
    <div class="contact__wrapper wrapper">
        <div class="contact__items">
            <div class="contact__item" *ngIf="!this.isSubmitted">
                <div class="contact__header">
                    <div class="contact__header-title">Напишите нам</div>
                    <div class="contact__header-sub">Напишите нам и мы ответим в ближайшее время на все интересующие вас вопросы!</div>
                </div>
                <div class="contact__form">
                    <div class="contact__form-content">
                        <div class="contact__form-item">
                            <input [(ngModel)]="fromName" class="input" type="text" placeholder="Ваше имя">
                        </div>
                        <div class="contact__form-item">
                            <input
                                    [ngClass]="{'error' : email.touched && email.invalid
                              && (email.control.hasError('pattern')
                              ||  email.control.hasError('required'))}"
                                    name="email"
                                    #email="ngModel"
                                    required="required"
                                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
                                    [(ngModel)]="fromEmail"
                                    class="input"
                                    type="email" placeholder="Email">
                        </div>
                        <div class="contact__form-item">
                            <textarea [(ngModel)]="text" class="textarea" placeholder="Комментарий"></textarea>
                        </div>
                    </div>
                    <button (click)="sendEmail()"  class="button">Отправить</button>
                </div>
            </div>
            <ng-container *ngIf="this.isSubmitted">
                <div class="contact__item contact__item--border">
                    <div class="contact__header">
                        <div class="contact__header-title">Напишите нам</div>
                        <div class="contact__header-sub">Напишите нам и мы ответим в ближайшее время на все интересующие вас вопросы!</div>
                    </div>
                    <div class="contact__sent">
                        <div class="contact__sent-header">
                            <div class="contact__sent-header-title">Сообщение отправлено!</div>
                        </div>
                        <div class="contact__sent-info">
                            Наши сотрудники скоро свяжутся с вами и ответят на все интересующие вопросы.
                        </div>

                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>


<div class="cover">
    <div class="cover__wrapper wrapper">
        <div class="cover__items">
            <div class="cover__item">
                <div class="cover__info">
                    <div class="cover__info-title">Мобильное приложение</div>
                    <div class="cover__info-sub">Социальный мониторинг</div>
                </div>
                <div class="cover__dscr">
                    Приложение помогает специалистам контролировать соблюдение самоизоляции и следить за самочувствием граждан. Если пользователь замечен в нарушении режима, операторы могут запросить обратную связь, а в случае ухудшения самочувствия – вызвать врача или скорую помощь.
                </div>
                <div class="cover__descriptor">
                    <div class="cover__descriptor-item">
                        <a href="mailto:pm@octopod.com" class="button">Связаться с нами</a>
                    </div>
                    <div class="cover__descriptor-item">
                        <div class="cover__descriptor-note">Напишите нам, чтобы узнать условия и стоимость внедрения решения в вашем регионе.</div>
                    </div>
                </div>
                <div class="cover__item-img">
                    <img src="assets/images/monitoring-cover.png"
                         alt="" class="cover__item-img-pic">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="cards">
    <div class="cards__item">
        <div class="cards__item-wrapper wrapper">
            <div class="cards__item-content">
                <div class="cards__item-header">
                    <div class="cards__item-title">Мониторинг состояния здоровья</div>
                </div>
                <div class="cards__item-dscr">
                    <div class="cards__item-dscr-info">
                        Служба-112 регулярно запрашивает данные о самочувствии гражданина — пользователь делится информацией о здоровье ежедневно. Если ему стало хуже, оператор вызовет врача
                        или скорую помощь.
                    </div>
                    <div class="cards__item-dscr-info">
                        Данные о самочувствии за период самоизоляции
                        или болезни хранятся в приложении — это позволяет отследить динамику и передать данные врачам.
                    </div>
                </div>
                <div class="cards__item-img">
                    <img src="assets/images/monitoring-cards_1.png" alt="" class="cards__item-img-pic">
                </div>
            </div>
        </div>
    </div>
    <div class="cards__item">
        <div class="cards__item-wrapper wrapper">
            <div class="cards__item-content">
                <div class="cards__item-header">
                    <div class="cards__item-title">Контроль самоизоляции в реальном времени</div>
                </div>
                <div class="cards__item-dscr">
                    <div class="card__item-dscr-info">
                        Мобильное приложение постоянно отслеживает координаты пользователя. Если по данным приложения пользователь покинул место самоизоляции, ему приходит уведомление о необходимости вернуться или с просьбой подтвердить, что он его не покидал. Уведомления о нарушении режима также приходят операторам. Оператор может незамедлительно связаться с пользователем, запросить фото или предпринять другие действия.
                    </div>
                </div>
                <div class="cards__item-img">
                    <img src="assets/images/monitoring-cards_2.png"
                         alt=""
                         class="cards__item-img-pic">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="docs">
    <div class="docs__wrapper wrapper">
        <div class="docs__header">
            <div class="docs__title">Документация</div>
        </div>
        <div class="docs__items">
            <div class="docs__item">
                <div class="docs__item-header">
                    <div class="docs__item-title">Руководства пользователей</div>
                </div>
                <div class="docs__item-elem" (click)="openPdf('assets/pdf/112_instruction.pdf')">
                    <div class="docs__item-icon">
                        <img src="assets/images/instruction.svg"
                             alt=""
                             class="docs__item-icon-pic">
                    </div>
                    <div class="docs__item-info">Руководство к мобильному приложению «112 – Экстренная помощь»</div>
                </div>
                <div class="docs__item-elem" (click)="openPdf('assets/pdf/112covid_instruction.pdf')">
                    <div class="docs__item-icon">
                        <img src="assets/images/instruction.svg"
                             alt=""
                             class="docs__item-icon-pic">
                    </div>
                    <div class="docs__item-info">
                        Руководство к разделу Карантин-социальный мониторинг (COVID-19)
                    </div>
                </div>
            </div>
            <div class="docs__item">
                <div class="docs__item-header">
                    <div class="docs__item-title">Безопасность и конфиденциальность</div>
                </div>
                <div class="docs__item-elem" [routerLink]="['/', 'agreement']">
                    <div class="docs__item-icon">
                        <img src="assets/images/instruction.svg"
                             alt=""
                             class="docs__item-icon-pic">
                    </div>
                    <div class="docs__item-info">Пользовательское соглашение приложения «112 – Экстренная помощь»</div>
                </div>
                <div class="docs__item-elem" [routerLink]="['/', 'agreeKursk']">
                    <div class="docs__item-icon">
                        <img src="assets/images/instruction.svg"
                             alt=""
                             class="docs__item-icon-pic">
                    </div>
                    <div class="docs__item-info">Условия обработки Персональных Данных (Курская область)</div>
                </div>
                <div class="docs__item-elem" [routerLink]="['/', 'agreeNovosib']">
                    <div class="docs__item-icon">
                        <img src="assets/images/instruction.svg"
                             alt=""
                             class="docs__item-icon-pic">
                    </div>
                    <div class="docs__item-info">Условия обработки Персональных Данных (Новосибирская область)</div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="contact" id="contacts">
    <div class="contact__wrapper wrapper">
        <div class="contact__items">
            <div class="contact__item" *ngIf="!this.isSubmitted">
                <div class="contact__header">
                    <div class="contact__header-title">Напишите нам</div>
                    <div class="contact__header-sub">Напишите нам и мы ответим в ближайшее время на все интересующие вас вопросы!</div>
                </div>
                <div class="contact__form">
                    <div class="contact__form-content">
                        <div class="contact__form-item">
                            <input [(ngModel)]="fromName" class="input" type="text" placeholder="Ваше имя">
                        </div>
                        <div class="contact__form-item">
                            <input
                                    [ngClass]="{'error' : email.touched && email.invalid
                              && (email.control.hasError('pattern')
                              ||  email.control.hasError('required'))}"
                                    name="email"
                                    #email="ngModel"
                                    required="required"
                                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$"
                                    [(ngModel)]="fromEmail"
                                    class="input"
                                    type="email" placeholder="Email">
                        </div>
                        <div class="contact__form-item">
                            <textarea [(ngModel)]="text" class="textarea" placeholder="Комментарий"></textarea>
                        </div>
                    </div>
                    <button (click)="sendEmail()"  class="button">Отправить</button>
                </div>
            </div>
            <ng-container *ngIf="this.isSubmitted">
                <div class="contact__item contact__item--border">
                    <div class="contact__header">
                        <div class="contact__header-title">Напишите нам</div>
                        <div class="contact__header-sub">Напишите нам и мы ответим в ближайшее время на все интересующие вас вопросы!</div>
                    </div>
                    <div class="contact__sent">
                        <div class="contact__sent-header">
                            <div class="contact__sent-header-title">Сообщение отправлено!</div>
                        </div>
                        <div class="contact__sent-info">
                            Наши сотрудники скоро свяжутся с вами и ответят на все интересующие вопросы.
                        </div>

                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

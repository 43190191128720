<div class="footer">
    <div class="footer__wrapper wrapper">
        <div class="footer__items">
            <div class="footer__item">
                <div class="footer__item-content">
                    <div class="footer__item-content-info">
                        <div class="footer__item-content-dscr">
                            Разработчик комплекса мобильных <br /> приложений Система–112:
                        </div>
                    </div>
                    <div class="footer__item-content-info">
                        <div class="footer__item-content-img">
                            <a href="https://octopod.com/">
                                <img src="assets/images/developer-octo.png"
                                     alt=""
                                     class="footer__item-content-img-pic">
                            </a>
                        </div>
                        <div class="footer__item-content-descriptor">
                            Разработка мобильных  приложений и информационных систем
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer__item">
                <div class="footer__item-content">
                    <div class="footer__item-content-info">
                        <div class="footer__item-content-dscr">По вопросам технической поддержки:</div>
                        <div class="footer__item-content-email">
                            <a class="link" href="mailto:support112@octopod.ru">support112@octopod.ru</a>
                        </div>
                    </div>
                    <div class="footer__item-content-info">
                        <div class="footer__item-content-descriptor">
                            <div class="footer__item-content-contact">
                                <a class="link" href="mailto:pm@octopod.com">pm@octopod.com</a>
                            </div>
                            <div class="footer__item-content-contact">
                                <a class="link" href="tel:+78123093921">+7 812 309-39-21</a>
                            </div>
                            <div class="footer__item-content-contact">
                                Санкт-Петербург, ул. Савушкина, дом 83
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

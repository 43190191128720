import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MailSenderService } from "../../services/mailSender.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router } from '@angular/router';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit, OnDestroy {

  fromName: string;
  fromEmail: string;
  text: string;
  isSubmitted: boolean;
  @ViewChild('email') email;
  private unsubscribe$ = new Subject();

  constructor(private mailService: MailSenderService,
              private router: Router) { }

  ngOnInit(): void {
    this.scrollTop();
  }

  checkUrl() {
    const currUrl = this.router.url;
    this.router.navigate([currUrl + '#contacts']);
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }

  sendEmail() {
    if (this.email.invalid) {
      this.email.control.markAsTouched();
      return;
    }
    const t = `Имя: ${this.fromName }\n` +
      `Email: ${ this.fromEmail }\n` +
      `Сообщение: ${this.text}`;
    const formData: any = new FormData();
    formData.append('to', 'support112@octopod.ru');
    formData.append('from', '112service.app' + '<' + 'noreply@112service.app' + '>');
    formData.append('text', t);
    formData.append('subject', 'Сообщение с сайта 112service.app')
    this.mailService.sendMessage(formData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.fromEmail = undefined;
        this.text = undefined;
        this.fromName = undefined;
      })
    this.isSubmitted = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

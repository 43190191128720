<app-site-header></app-site-header>

<router-outlet></router-outlet>

<app-site-footer></app-site-footer>


<!--<div class="main-container">-->
<!--  <img class="logo" src="/assets/images/112-logo.svg">-->
<!--  <h1>112 – Экстренная помощь</h1>-->
<!--  <h2>Мобильное приложение</h2>-->
<!--  <button class="app-button app-store"-->
<!--          type="button"-->
<!--          (click)="goTo('https://apps.apple.com/ru/app/id1499805544')"-->
<!--  ></button>-->
<!--  <button class="app-button google-play"-->
<!--          type="button"-->
<!--          (click)="goTo('https://play.google.com/store/apps/details?id=com.octopod.service112')"-->
<!--  ></button>-->
<!--</div>-->
<!--<div class="carousel-wrap">-->
<!--  <app-carousel></app-carousel>-->
<!--</div>-->
<!--<div class="main-container manual-buttons-wrap">-->
<!--  <div class="manual-buttons__title">-->
<!--    <span class="manual-buttons__title-text">Руководства пользователей</span>-->
<!--  </div>-->
<!--  <div class="manual-button-wrap" (click)="openPdf('assets/pdf/112_instruction.pdf')">-->
<!--    <button type="button" class="manual-button-icon" ></button>-->
<!--    <label class="manual-button-text">Руководство к мобильному приложению «112 – Экстренная помощь»</label>-->
<!--  </div>-->
<!--  <div class="manual-button-wrap manual-button-margin"(click)="openPdf('assets/pdf/112covid_instruction.pdf')">-->
<!--    <button type="button" class="manual-button-icon"></button>-->
<!--    <label class="manual-button-text">Руководство к разделу Карантин-социальный мониторинг (COVID-19)</label>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="main-container manual-buttons-wrap">-->
<!--  <div class="manual-buttons__title">-->
<!--    <span class="manual-buttons__title-text">Безопасность и конфиденциальность</span>-->
<!--  </div>-->
<!--  <div class="manual-button-wrap" routerLink="agreement">-->
<!--    <button type="button" class="manual-button-icon"></button>-->
<!--    <label class="manual-button-text">Пользовательское соглашение приложения «112 – Экстренная помощь»</label>-->
<!--  </div>-->
<!--  <div class="manual-button-wrap manual-button-margin"routerLink="agreeKursk"-->
<!--  >-->
<!--    <button type="button" class="manual-button-icon"-->

<!--    ></button>-->
<!--    <label class="manual-button-text">Условия обработки Персональных Данных (Курская область)</label>-->
<!--  </div>-->
<!--  <div class="manual-button-wrap manual-button-margin" routerLink="agreeNovosib"-->
<!--  >-->
<!--    <button type="button" class="manual-button-icon"-->

<!--    ></button>-->
<!--    <label class="manual-button-text">Условия обработки Персональных Данных (Новосибирская область)</label>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="main-container bottom-mail-wrap">-->
<!--  <label class="bottom-mail-label">По вопросам технической поддержки обращайтесь:-->
<!--    <button class="bottom-mail-label-btn" ngxClipboard [cbContent]="'support112@octopod.ru'"-->
<!--            matTooltip="Нажмите на почту, чтобы скопировать">-->
<!--      <a class="mail-link" href="mailto:support112@octopod.ru">support112@octopod.ru</a>-->
<!--    </button>-->
<!--  </label>-->
<!--</div>-->

import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
  host: {
    '(document:click)': 'onClickOutside($event)',
  },
})
export class SiteHeaderComponent implements OnInit {

  @ViewChild('menu', {static: false}) menu;

  isOpenMenu: boolean;
  $event: Event;
  isSticky: boolean = false;
  url: string;
  private unsubscribe$ = new Subject();
  isChanged: boolean;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 100;
  }

  constructor(private router: Router) { }

  ngOnInit() {
    this.checkUrl();
    this.url = this.router.url;
  }

  checkUrl() {
    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.isChanged = false;
          this.url = val.url;
          if (this.url.includes('#contacts')) {
            this.isChanged = true;
          }
        }
      });
  }

  onClickOutside(event) {
    if (this.menu.nativeElement.contains(event.target)) {
      this.isOpenMenu = false;
      window.document.body.style.overflow = 'auto';
    }
  }

  openMenu() {
    this.isOpenMenu = !this.isOpenMenu;
    if(this.isOpenMenu) {
      window.document.body.style.overflow = 'hidden';
    } else {
      window.document.body.style.overflow = 'auto';
    }
  }
}

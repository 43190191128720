import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-agree-anon',
  templateUrl: './agree-anon.component.html',
  styleUrls: ['./agree-anon.component.scss']
})
export class AgreeAnonComponent implements OnInit {

  constructor(
    private viewportScroller: ViewportScroller,
  ) { }

  ngOnInit(): void {
    this.toTop();
  }
  toTop() {
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}

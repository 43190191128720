import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class MailSenderService {
  url = 'https://api.mailgun.net/v3/mg.octopod.com/messages';

  constructor(private http: HttpClient) {
  }

  sendMessage(fromData: FormData) {
    let headers = new HttpHeaders({
      Authorization: 'Basic YXBpOmtleS1iYzQ3NzI2NzQwMzc0YTQ3MDQ4M2Q4ZDUwZTQxZjU0MA=='
    });
    return this.http.post(this.url, fromData, {headers});
  }

}
